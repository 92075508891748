$(function() {
	// extends
	$.fn.extend({
	    animated: function (animation, callback) {
	        var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
	        this.addClass('animated ' + animation).one(animationEnd, function(e) {
	            $(this).removeClass('animated ' + animation);
	            callback && callback.apply(this, e);
	        });
	    },
	    showmore: function(options) {
	    	options = $.extend({}, {
	    		showChar: 90,
	    		ellipses: '&hellip;',
	    		moreText: '<small>Show more</small>',
	    		lessText: '<small>Show less</small>'
	    	}, options);

		    this.each(function() {
		    	var $this = $(this);
		        var content = $this.html();

		        if (content.length > options.showChar) {

		            var c = content.substr(0, options.showChar);
		            var h = content.substr(options.showChar, content.length - options.showChar);

		            var html = c + '<span class="moreellipses"> ' + options.ellipses+ ' </span><span><span style="display: none;">' + h + '</span><a href="#" class="morelink" style="display: block;">' + options.moreText + '</a></span>';

		            $this.html(html);

		            $this.find('.morelink').on('click', function(e) {
				    	e.preventDefault();

				    	if ($(this).hasClass("less")) {
				    		$(this).removeClass("less");
				    		$(this).html(options.moreText);
				    	} else {
				    		$(this).addClass("less");
				    		$(this).html(options.lessText);
				    	}

				    	$(this).parent().prev().toggle();
				    	$(this).prev().toggle();
				    });
		        }
		    });
	    },
	    range: function(options) {
	    	this.each(function() {
	    		var $this = $(this),
	    			$preview;

	    		if ($this.parents('.range-container').length === 0) {
	    			var $parent = $('<div class="range-container d-flex"></div>');
	    			$this.wrap($parent);
	    			$this.after('<small class="badge badge-danger range-preview ml-2">' + $this.val() + '</small>');
	    		}

	    		$preview = $this.next('.range-preview');
	    		$this.on('input', function(e) {
	    			$preview.text($(this).val());
	    		});
	    	});
	    }
	});

	plugins.init();
	pjax.init();
});

var plugins = {
	init: function() {

		// tooltips
		$('[data-toggle="tooltip"]').tooltip();

		// showmore
		$('[data-toggle="showmore"]').showmore();
	}
}

var pjax = {
	init: function() {
		$.pjax.defaults.timeout = 4000;
	}
}

$.ajaxModal = function(options, callback) {
	// var $modal;

	options = $.extend({}, typeof options === 'string' ? { url: options } : options);
	if (!options.url) {
		console.error('remote url not provided.');
		return;
	}

    var $modal = $('\
        <div class="modal fade" id="modal-ajax">\
            <div class="modal-dialog" role="document">\
                <div class="modal-content"></div>\
            </div>\
        </div>');

	var $dialog = $modal.find('.modal-dialog');
	var $content = $dialog.find('.modal-content');

	if (options.size) {
		$dialog.addClass('modal-' + options.size);
	}

	$content.html('\
        <div class="modal-body">\
            <div class="modal-title ajax-modal-placeholder">\
                <div class="ajax-loader animate fadeIn">\
                    <div class="ajax-loader__spinner">\
                        <svg viewBox="25 25 50 50">\
                            <circle cx="50" cy="50" r="20" fill="none" stroke-width="4" stroke-miterlimit="10" />\
                        </svg>\
                    </div>\
                    <p>Loading content&hellip;</p>\
                </div>\
            </div>\
        </div>');

    $.get(options.url, function(content) {
        $content.html(content);
        if (callback) callback.call($modal.get(0));

        if (options.formHandler) {
            var $form = $content.find('form');
            if ($form) {
                $form.on('submit', function(e) {
                    e.preventDefault();
                    $.exec(options.formHandler, window, this, $modal);
                });
            }
        }

    }).fail(function(xhr, status, error) {
        $content.find('.ajax-modal-placeholder').html('<strong class="text-danger">' + xhr.status + ' ' + error + '</strong>');
    });

	$modal.modal()
		.on('shown.bs.modal', function() {
            if (options.shownHandler) {
                $.exec(options.shownHandler, window, this);
            }

		})
		.on('hidden.bs.modal', function() {
			$(this).removeData('bs.modal').find('.modal-content').eq(0).html('');
            $modal.remove();
		});

    return $modal;
}

window.utils.radiusToZoom = function(radius) {
    return Math.round(14-Math.log(radius)/Math.LN2);
}

window.utils.getQuery = function(q) {
	var match,
        pl     = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
        query  = window.location.search.substring(1);

    urlParams = {};
    while (match = search.exec(query))
       urlParams[decode(match[1])] = decode(match[2]);

   return urlParams[q];
}